// Function to convert HSL to Hex
function hslToHex(h, s, l) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0");
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

// Generate a pleasant random color using HSL
function generatePleasantColor() {
  // Hue: 0-360 degrees
  const hue = Math.floor(Math.random() * 360);
  // Saturation: 65-90% for vibrant but not overwhelming colors
  const saturation = Math.floor(Math.random() * 25) + 65;
  const lightness = Math.floor(Math.random() * 20) + 45;

  return hslToHex(hue, saturation, lightness);
}

function generateUniqueColors() {
  let colors = new Set();

  while (colors.size < 100) {
    let color = generatePleasantColor();
    colors.add(color);
  }

  return Array.from(colors);
}

const uniqueColors = generateUniqueColors();

export default uniqueColors;
