import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9b9f232e = () => interopDefault(import('../pages/404/index.vue' /* webpackChunkName: "pages/404/index" */))
const _00588a40 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _004031e8 = () => interopDefault(import('../pages/admin/index/admin_users/index.vue' /* webpackChunkName: "pages/admin/index/admin_users/index" */))
const _da0ec758 = () => interopDefault(import('../pages/admin/index/apps/index.vue' /* webpackChunkName: "pages/admin/index/apps/index" */))
const _4e82b1e0 = () => interopDefault(import('../pages/admin/index/dispensaries/index.vue' /* webpackChunkName: "pages/admin/index/dispensaries/index" */))
const _1e9f2376 = () => interopDefault(import('../pages/admin/index/groups/index.vue' /* webpackChunkName: "pages/admin/index/groups/index" */))
const _4f221f19 = () => interopDefault(import('../pages/admin/index/import_patients.vue' /* webpackChunkName: "pages/admin/index/import_patients" */))
const _2e659ce6 = () => interopDefault(import('../pages/admin/index/measurement_durations.vue' /* webpackChunkName: "pages/admin/index/measurement_durations" */))
const _e0462a46 = () => interopDefault(import('../pages/admin/index/onboard_medications.vue' /* webpackChunkName: "pages/admin/index/onboard_medications" */))
const _734e7158 = () => interopDefault(import('../pages/admin/index/organizations/index.vue' /* webpackChunkName: "pages/admin/index/organizations/index" */))
const _0d683a48 = () => interopDefault(import('../pages/admin/index/payment_coupons/index.vue' /* webpackChunkName: "pages/admin/index/payment_coupons/index" */))
const _e3073ac8 = () => interopDefault(import('../pages/admin/index/provider_compensation/index.vue' /* webpackChunkName: "pages/admin/index/provider_compensation/index" */))
const _79a73ca6 = () => interopDefault(import('../pages/admin/index/referal_coupons/index.vue' /* webpackChunkName: "pages/admin/index/referal_coupons/index" */))
const _577fb698 = () => interopDefault(import('../pages/admin/index/rewards/index.vue' /* webpackChunkName: "pages/admin/index/rewards/index" */))
const _0cd8d92e = () => interopDefault(import('../pages/admin/index/roles.vue' /* webpackChunkName: "pages/admin/index/roles" */))
const _155a6fc0 = () => interopDefault(import('../pages/admin/index/services/index.vue' /* webpackChunkName: "pages/admin/index/services/index" */))
const _3c9e3e93 = () => interopDefault(import('../pages/admin/index/specialists.vue' /* webpackChunkName: "pages/admin/index/specialists" */))
const _703393b8 = () => interopDefault(import('../pages/admin/index/states/index.vue' /* webpackChunkName: "pages/admin/index/states/index" */))
const _4d37b12a = () => interopDefault(import('../pages/admin/index/tracks/index.vue' /* webpackChunkName: "pages/admin/index/tracks/index" */))
const _3bb6e2bc = () => interopDefault(import('../pages/admin/index/users/index.vue' /* webpackChunkName: "pages/admin/index/users/index" */))
const _c8097648 = () => interopDefault(import('../pages/admin/index/website_hours.vue' /* webpackChunkName: "pages/admin/index/website_hours" */))
const _6f177f8a = () => interopDefault(import('../pages/admin/index/documents/shared.vue' /* webpackChunkName: "pages/admin/index/documents/shared" */))
const _460cd3f0 = () => interopDefault(import('../pages/admin/index/groups/create.vue' /* webpackChunkName: "pages/admin/index/groups/create" */))
const _fbcddacc = () => interopDefault(import('../pages/admin/index/library/topics/index.vue' /* webpackChunkName: "pages/admin/index/library/topics/index" */))
const _5859cf74 = () => interopDefault(import('../pages/admin/index/tracks/alert/_alertId/index.vue' /* webpackChunkName: "pages/admin/index/tracks/alert/_alertId/index" */))
const _3f36515d = () => interopDefault(import('../pages/admin/index/library/topics/_id/articles/index.vue' /* webpackChunkName: "pages/admin/index/library/topics/_id/articles/index" */))
const _21ad9361 = () => interopDefault(import('../pages/admin/index/groups/_id/index.vue' /* webpackChunkName: "pages/admin/index/groups/_id/index" */))
const _11202715 = () => interopDefault(import('../pages/admin/index/tracks/_id/index.vue' /* webpackChunkName: "pages/admin/index/tracks/_id/index" */))
const _010bb2dc = () => interopDefault(import('../pages/admin/index/users/_userId/index.vue' /* webpackChunkName: "pages/admin/index/users/_userId/index" */))
const _11c6922d = () => interopDefault(import('../pages/admin/index/apps/_id/edit.vue' /* webpackChunkName: "pages/admin/index/apps/_id/edit" */))
const _df7f3974 = () => interopDefault(import('../pages/admin/index/organizations/_id/settings.vue' /* webpackChunkName: "pages/admin/index/organizations/_id/settings" */))
const _679c151c = () => interopDefault(import('../pages/alerts/index.vue' /* webpackChunkName: "pages/alerts/index" */))
const _27cf61b4 = () => interopDefault(import('../pages/amdiabetes/index.vue' /* webpackChunkName: "pages/amdiabetes/index" */))
const _4d8ff90e = () => interopDefault(import('../pages/appointments/index.vue' /* webpackChunkName: "pages/appointments/index" */))
const _30892d3c = () => interopDefault(import('../pages/cannahealrx/index.vue' /* webpackChunkName: "pages/cannahealrx/index" */))
const _4a7bd00c = () => interopDefault(import('../pages/chats/index.vue' /* webpackChunkName: "pages/chats/index" */))
const _6aae13b2 = () => interopDefault(import('../pages/civiclee/index.vue' /* webpackChunkName: "pages/civiclee/index" */))
const _aef8b776 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _7d05a12e = () => interopDefault(import('../pages/documents/index.vue' /* webpackChunkName: "pages/documents/index" */))
const _1fb5f954 = () => interopDefault(import('../pages/general_chat/index.vue' /* webpackChunkName: "pages/general_chat/index" */))
const _37d571f5 = () => interopDefault(import('../pages/goodcannanow/index.vue' /* webpackChunkName: "pages/goodcannanow/index" */))
const _51da0de6 = () => interopDefault(import('../pages/inventory/index.vue' /* webpackChunkName: "pages/inventory/index" */))
const _615db1df = () => interopDefault(import('../pages/magic-link/index.vue' /* webpackChunkName: "pages/magic-link/index" */))
const _d00a66a8 = () => interopDefault(import('../pages/meeting/index.vue' /* webpackChunkName: "pages/meeting/index" */))
const _3a19ba99 = () => interopDefault(import('../pages/notifications/index.vue' /* webpackChunkName: "pages/notifications/index" */))
const _776ebc88 = () => interopDefault(import('../pages/oasis/index.vue' /* webpackChunkName: "pages/oasis/index" */))
const _b024f21e = () => interopDefault(import('../pages/organization_appointments/index.vue' /* webpackChunkName: "pages/organization_appointments/index" */))
const _73bb7cbb = () => interopDefault(import('../pages/overview.vue' /* webpackChunkName: "pages/overview" */))
const _3b3e7db2 = () => interopDefault(import('../pages/patient_filters/index.vue' /* webpackChunkName: "pages/patient_filters/index" */))
const _936a455a = () => interopDefault(import('../pages/patients/index.vue' /* webpackChunkName: "pages/patients/index" */))
const _24b5cacc = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _bd248e5a = () => interopDefault(import('../pages/providers/index.vue' /* webpackChunkName: "pages/providers/index" */))
const _7b6095fe = () => interopDefault(import('../pages/providers/index/_id/index.vue' /* webpackChunkName: "pages/providers/index/_id/index" */))
const _266b4f6c = () => interopDefault(import('../pages/readings/index.vue' /* webpackChunkName: "pages/readings/index" */))
const _2631d89c = () => interopDefault(import('../pages/reminders/index.vue' /* webpackChunkName: "pages/reminders/index" */))
const _29e23f30 = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _15dd80b2 = () => interopDefault(import('../pages/reports/index/conversation_reports.vue' /* webpackChunkName: "pages/reports/index/conversation_reports" */))
const _1bef8941 = () => interopDefault(import('../pages/reports/index/multiple_patients/index.vue' /* webpackChunkName: "pages/reports/index/multiple_patients/index" */))
const _7731fb3f = () => interopDefault(import('../pages/reports/index/multiple_patients/index/ccm.vue' /* webpackChunkName: "pages/reports/index/multiple_patients/index/ccm" */))
const _77be819a = () => interopDefault(import('../pages/reports/index/multiple_patients/index/general.vue' /* webpackChunkName: "pages/reports/index/multiple_patients/index/general" */))
const _475e4446 = () => interopDefault(import('../pages/reports/index/single_patients.vue' /* webpackChunkName: "pages/reports/index/single_patients" */))
const _a1d5adca = () => interopDefault(import('../pages/team_monitoring/index.vue' /* webpackChunkName: "pages/team_monitoring/index" */))
const _2b5e6d83 = () => interopDefault(import('../pages/testPage.vue' /* webpackChunkName: "pages/testPage" */))
const _279880d8 = () => interopDefault(import('../pages/auth/forbidden.vue' /* webpackChunkName: "pages/auth/forbidden" */))
const _00db0160 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _24cdd6f8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _129a847f = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _476b85d8 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _55dc5f4a = () => interopDefault(import('../pages/auth/success-fitbit.vue' /* webpackChunkName: "pages/auth/success-fitbit" */))
const _944de74c = () => interopDefault(import('../pages/auth/two_factor/index.vue' /* webpackChunkName: "pages/auth/two_factor/index" */))
const _7083c354 = () => interopDefault(import('../pages/cannahealrx/appointments.vue' /* webpackChunkName: "pages/cannahealrx/appointments" */))
const _257ec852 = () => interopDefault(import('../pages/cannahealrx/approved.vue' /* webpackChunkName: "pages/cannahealrx/approved" */))
const _259c4b09 = () => interopDefault(import('../pages/cannahealrx/book.vue' /* webpackChunkName: "pages/cannahealrx/book" */))
const _2ce7612e = () => interopDefault(import('../pages/cannahealrx/booking.vue' /* webpackChunkName: "pages/cannahealrx/booking" */))
const _a691866a = () => interopDefault(import('../pages/cannahealrx/no_show.vue' /* webpackChunkName: "pages/cannahealrx/no_show" */))
const _b321b0a4 = () => interopDefault(import('../pages/cannahealrx/patients.vue' /* webpackChunkName: "pages/cannahealrx/patients" */))
const _fea7e3c4 = () => interopDefault(import('../pages/cannahealrx/rejected.vue' /* webpackChunkName: "pages/cannahealrx/rejected" */))
const _f85929a0 = () => interopDefault(import('../pages/civiclee/indexOld.vue' /* webpackChunkName: "pages/civiclee/indexOld" */))
const _5e703ca8 = () => interopDefault(import('../pages/civiclee/landing.vue' /* webpackChunkName: "pages/civiclee/landing" */))
const _6d6103a2 = () => interopDefault(import('../pages/civiclee/landinggpt.vue' /* webpackChunkName: "pages/civiclee/landinggpt" */))
const _67f5d4ea = () => interopDefault(import('../pages/civiclee/landingOld.vue' /* webpackChunkName: "pages/civiclee/landingOld" */))
const _1e36a89e = () => interopDefault(import('../pages/civiclee/members/index.vue' /* webpackChunkName: "pages/civiclee/members/index" */))
const _71de5d96 = () => interopDefault(import('../pages/civiclee/onboarding.vue' /* webpackChunkName: "pages/civiclee/onboarding" */))
const _678c1198 = () => interopDefault(import('../pages/civiclee/success.vue' /* webpackChunkName: "pages/civiclee/success" */))
const _5da89149 = () => interopDefault(import('../pages/dashboard/all_patients.vue' /* webpackChunkName: "pages/dashboard/all_patients" */))
const _0f978152 = () => interopDefault(import('../pages/dashboard/call_list.vue' /* webpackChunkName: "pages/dashboard/call_list" */))
const _de7e5218 = () => interopDefault(import('../pages/dashboard/my_patients.vue' /* webpackChunkName: "pages/dashboard/my_patients" */))
const _4ab1d238 = () => interopDefault(import('../pages/dashboard/onboarding.vue' /* webpackChunkName: "pages/dashboard/onboarding" */))
const _0525e1df = () => interopDefault(import('../pages/dental/appointments.vue' /* webpackChunkName: "pages/dental/appointments" */))
const _08fb4ff0 = () => interopDefault(import('../pages/dental/library_de.vue' /* webpackChunkName: "pages/dental/library_de" */))
const _0b2efc18 = () => interopDefault(import('../pages/dental/library_en.vue' /* webpackChunkName: "pages/dental/library_en" */))
const _3527fc86 = () => interopDefault(import('../pages/dental/onboarding.vue' /* webpackChunkName: "pages/dental/onboarding" */))
const _b636feae = () => interopDefault(import('../pages/halee/landing.vue' /* webpackChunkName: "pages/halee/landing" */))
const _5a325454 = () => interopDefault(import('../pages/inventory/upload.vue' /* webpackChunkName: "pages/inventory/upload" */))
const _6a15c572 = () => interopDefault(import('../pages/magic-link/expired.vue' /* webpackChunkName: "pages/magic-link/expired" */))
const _2e852fea = () => interopDefault(import('../pages/monitoring/rpm.vue' /* webpackChunkName: "pages/monitoring/rpm" */))
const _1068cff2 = () => interopDefault(import('../pages/oasis/all_patients.vue' /* webpackChunkName: "pages/oasis/all_patients" */))
const _2dccc1aa = () => interopDefault(import('../pages/oasis/my_patients.vue' /* webpackChunkName: "pages/oasis/my_patients" */))
const _6e93a721 = () => interopDefault(import('../pages/oasis/onboarding.vue' /* webpackChunkName: "pages/oasis/onboarding" */))
const _29dea8de = () => interopDefault(import('../pages/oasis/review.vue' /* webpackChunkName: "pages/oasis/review" */))
const _1b5d991a = () => interopDefault(import('../pages/reports/loading.vue' /* webpackChunkName: "pages/reports/loading" */))
const _00fac126 = () => interopDefault(import('../pages/auth/patient/delete/index.vue' /* webpackChunkName: "pages/auth/patient/delete/index" */))
const _8c1c1da0 = () => interopDefault(import('../pages/charts/apex/data.js' /* webpackChunkName: "pages/charts/apex/data" */))
const _7ba10f0c = () => interopDefault(import('../pages/charts/chartjs/DonutChart.vue' /* webpackChunkName: "pages/charts/chartjs/DonutChart" */))
const _66befd44 = () => interopDefault(import('../pages/auth/patient/delete/success.vue' /* webpackChunkName: "pages/auth/patient/delete/success" */))
const _036b2ed6 = () => interopDefault(import('../pages/auth/patient/delete/_identifier/verify.vue' /* webpackChunkName: "pages/auth/patient/delete/_identifier/verify" */))
const _1a426815 = () => interopDefault(import('../pages/auth/reset/_id.vue' /* webpackChunkName: "pages/auth/reset/_id" */))
const _fddb457c = () => interopDefault(import('../pages/auth/two_factor/_id.vue' /* webpackChunkName: "pages/auth/two_factor/_id" */))
const _93e72fea = () => interopDefault(import('../pages/civiclee/users/_id/index.vue' /* webpackChunkName: "pages/civiclee/users/_id/index" */))
const _3d09071f = () => interopDefault(import('../pages/dashboard/merge_patient/_id/index.vue' /* webpackChunkName: "pages/dashboard/merge_patient/_id/index" */))
const _62c490d3 = () => interopDefault(import('../pages/civiclee/users/_id/overview/index.vue' /* webpackChunkName: "pages/civiclee/users/_id/overview/index" */))
const _2436ae76 = () => interopDefault(import('../pages/dashboard/merge_patient/_id/_cid/index.vue' /* webpackChunkName: "pages/dashboard/merge_patient/_id/_cid/index" */))
const _2618c06c = () => interopDefault(import('../pages/amdiabetes/_slug.vue' /* webpackChunkName: "pages/amdiabetes/_slug" */))
const _250df821 = () => interopDefault(import('../pages/cannahealrx/_status.vue' /* webpackChunkName: "pages/cannahealrx/_status" */))
const _632862d9 = () => interopDefault(import('../pages/magic-link/_code.vue' /* webpackChunkName: "pages/magic-link/_code" */))
const _73f49cd2 = () => interopDefault(import('../pages/meeting/_id/index.vue' /* webpackChunkName: "pages/meeting/_id/index" */))
const _044554f8 = () => interopDefault(import('../pages/monitoring/_id/index.vue' /* webpackChunkName: "pages/monitoring/_id/index" */))
const _2769b98b = () => interopDefault(import('../pages/onboarding/_id/index.vue' /* webpackChunkName: "pages/onboarding/_id/index" */))
const _d9521620 = () => interopDefault(import('../pages/onboarding/_id/index/address.vue' /* webpackChunkName: "pages/onboarding/_id/index/address" */))
const _61db5bda = () => interopDefault(import('../pages/onboarding/_id/index/care_plan.vue' /* webpackChunkName: "pages/onboarding/_id/index/care_plan" */))
const _145e6fdb = () => interopDefault(import('../pages/onboarding/_id/index/care_provider.vue' /* webpackChunkName: "pages/onboarding/_id/index/care_provider" */))
const _3042f1c8 = () => interopDefault(import('../pages/onboarding/_id/index/contact.vue' /* webpackChunkName: "pages/onboarding/_id/index/contact" */))
const _0980d854 = () => interopDefault(import('../pages/onboarding/_id/index/insurance.vue' /* webpackChunkName: "pages/onboarding/_id/index/insurance" */))
const _5eb592ee = () => interopDefault(import('../pages/onboarding/_id/index/patient_information.vue' /* webpackChunkName: "pages/onboarding/_id/index/patient_information" */))
const _40c3d55e = () => interopDefault(import('../pages/onboarding/_id/index/specialists.vue' /* webpackChunkName: "pages/onboarding/_id/index/specialists" */))
const _c3817eec = () => interopDefault(import('../pages/onboarding/_id/index/tasks.vue' /* webpackChunkName: "pages/onboarding/_id/index/tasks" */))
const _634520c6 = () => interopDefault(import('../pages/patient_filters/_id/index.vue' /* webpackChunkName: "pages/patient_filters/_id/index" */))
const _c7bddf7a = () => interopDefault(import('../pages/pg/_token/index.vue' /* webpackChunkName: "pages/pg/_token/index" */))
const _73bc22b8 = () => interopDefault(import('../pages/readings/_rid.vue' /* webpackChunkName: "pages/readings/_rid" */))
const _c6fe937a = () => interopDefault(import('../pages/team_monitoring/_id.vue' /* webpackChunkName: "pages/team_monitoring/_id" */))
const _314d4fb8 = () => interopDefault(import('../pages/users/_id/index.vue' /* webpackChunkName: "pages/users/_id/index" */))
const _73da1f7f = () => interopDefault(import('../pages/meeting/_id/ended.vue' /* webpackChunkName: "pages/meeting/_id/ended" */))
const _69fe71b4 = () => interopDefault(import('../pages/profile/_id/schedule.vue' /* webpackChunkName: "pages/profile/_id/schedule" */))
const _7c6f09c5 = () => interopDefault(import('../pages/users/_id/achievements/index.vue' /* webpackChunkName: "pages/users/_id/achievements/index" */))
const _670c3df8 = () => interopDefault(import('../pages/users/_id/alerts/index.vue' /* webpackChunkName: "pages/users/_id/alerts/index" */))
const _1875d156 = () => interopDefault(import('../pages/users/_id/appointments/index.vue' /* webpackChunkName: "pages/users/_id/appointments/index" */))
const _9ce574f6 = () => interopDefault(import('../pages/users/_id/attachments/index.vue' /* webpackChunkName: "pages/users/_id/attachments/index" */))
const _1a5507a4 = () => interopDefault(import('../pages/users/_id/attachments/index/documents.vue' /* webpackChunkName: "pages/users/_id/attachments/index/documents" */))
const _75cfc2f2 = () => interopDefault(import('../pages/users/_id/attachments/index/images.vue' /* webpackChunkName: "pages/users/_id/attachments/index/images" */))
const _6b604bd2 = () => interopDefault(import('../pages/users/_id/attachments/index/videos.vue' /* webpackChunkName: "pages/users/_id/attachments/index/videos" */))
const _516e6d5e = () => interopDefault(import('../pages/users/_id/badges/index.vue' /* webpackChunkName: "pages/users/_id/badges/index" */))
const _70e32bf4 = () => interopDefault(import('../pages/users/_id/cannahealrx/index.vue' /* webpackChunkName: "pages/users/_id/cannahealrx/index" */))
const _3edcbd84 = () => interopDefault(import('../pages/users/_id/communications/index.vue' /* webpackChunkName: "pages/users/_id/communications/index" */))
const _00b25efe = () => interopDefault(import('../pages/users/_id/communications/index/phone-call.vue' /* webpackChunkName: "pages/users/_id/communications/index/phone-call" */))
const _d5c978e4 = () => interopDefault(import('../pages/users/_id/communications/index/text.vue' /* webpackChunkName: "pages/users/_id/communications/index/text" */))
const _29f7d4c3 = () => interopDefault(import('../pages/users/_id/communications/index/video_call.vue' /* webpackChunkName: "pages/users/_id/communications/index/video_call" */))
const _45a67de9 = () => interopDefault(import('../pages/users/_id/dashboard/index.vue' /* webpackChunkName: "pages/users/_id/dashboard/index" */))
const _1208bf72 = () => interopDefault(import('../pages/users/_id/devices/index.vue' /* webpackChunkName: "pages/users/_id/devices/index" */))
const _5ea0090d = () => interopDefault(import('../pages/users/_id/documents/index.vue' /* webpackChunkName: "pages/users/_id/documents/index" */))
const _c3e23688 = () => interopDefault(import('../pages/users/_id/insights/index.vue' /* webpackChunkName: "pages/users/_id/insights/index" */))
const _4ddfea94 = () => interopDefault(import('../pages/users/_id/more/index.vue' /* webpackChunkName: "pages/users/_id/more/index" */))
const _e7f80a54 = () => interopDefault(import('../pages/users/_id/notes/index.vue' /* webpackChunkName: "pages/users/_id/notes/index" */))
const _87fc2540 = () => interopDefault(import('../pages/users/_id/oasis/index.vue' /* webpackChunkName: "pages/users/_id/oasis/index" */))
const _c79ca64c = () => interopDefault(import('../pages/users/_id/overview/index.vue' /* webpackChunkName: "pages/users/_id/overview/index" */))
const _b0b00e3e = () => interopDefault(import('../pages/users/_id/overview/index/fitbit.vue' /* webpackChunkName: "pages/users/_id/overview/index/fitbit" */))
const _98e778a8 = () => interopDefault(import('../pages/users/_id/overview/index/lab-vitals.vue' /* webpackChunkName: "pages/users/_id/overview/index/lab-vitals" */))
const _7392434f = () => interopDefault(import('../pages/users/_id/overview/index/medications.vue' /* webpackChunkName: "pages/users/_id/overview/index/medications" */))
const _872d7c28 = () => interopDefault(import('../pages/users/_id/overview/index/vitals.vue' /* webpackChunkName: "pages/users/_id/overview/index/vitals" */))
const _362f8dc8 = () => interopDefault(import('../pages/users/_id/playground/index.vue' /* webpackChunkName: "pages/users/_id/playground/index" */))
const _187731d4 = () => interopDefault(import('../pages/users/_id/reports/index.vue' /* webpackChunkName: "pages/users/_id/reports/index" */))
const _8f88860e = () => interopDefault(import('../pages/users/_id/rewards/index.vue' /* webpackChunkName: "pages/users/_id/rewards/index" */))
const _0b9db722 = () => interopDefault(import('../pages/users/_id/timers/index.vue' /* webpackChunkName: "pages/users/_id/timers/index" */))
const _79a0daa9 = () => interopDefault(import('../pages/users/_id/tracks/index.vue' /* webpackChunkName: "pages/users/_id/tracks/index" */))
const _62e30075 = () => interopDefault(import('../pages/users/_id/cannahealrx/notes.vue' /* webpackChunkName: "pages/users/_id/cannahealrx/notes" */))
const _9f053c74 = () => interopDefault(import('../pages/users/_id/dental/appointments/index.vue' /* webpackChunkName: "pages/users/_id/dental/appointments/index" */))
const _5b788082 = () => interopDefault(import('../pages/users/_id/dental/notes.vue' /* webpackChunkName: "pages/users/_id/dental/notes" */))
const _9f6a506a = () => interopDefault(import('../pages/users/_id/dental/overview/index.vue' /* webpackChunkName: "pages/users/_id/dental/overview/index" */))
const _c2f680d0 = () => interopDefault(import('../pages/users/_id/dental/overview/index/achievements.vue' /* webpackChunkName: "pages/users/_id/dental/overview/index/achievements" */))
const _6ee0aeca = () => interopDefault(import('../pages/users/_id/dental/overview/index/tasks.vue' /* webpackChunkName: "pages/users/_id/dental/overview/index/tasks" */))
const _34c7b0a8 = () => interopDefault(import('../pages/users/_id/oasis/allergies.vue' /* webpackChunkName: "pages/users/_id/oasis/allergies" */))
const _6b5df2a7 = () => interopDefault(import('../pages/users/_id/oasis/assessment/index.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/index" */))
const _ced1ec28 = () => interopDefault(import('../pages/users/_id/oasis/edit.vue' /* webpackChunkName: "pages/users/_id/oasis/edit" */))
const _3bded6cc = () => interopDefault(import('../pages/users/_id/oasis/lab-data.vue' /* webpackChunkName: "pages/users/_id/oasis/lab-data" */))
const _95857556 = () => interopDefault(import('../pages/users/_id/oasis/medical-claims.vue' /* webpackChunkName: "pages/users/_id/oasis/medical-claims" */))
const _707dcadc = () => interopDefault(import('../pages/users/_id/oasis/medications.vue' /* webpackChunkName: "pages/users/_id/oasis/medications" */))
const _575683cf = () => interopDefault(import('../pages/users/_id/oasis/notes.vue' /* webpackChunkName: "pages/users/_id/oasis/notes" */))
const _0b6da1d6 = () => interopDefault(import('../pages/users/_id/oasis/pharmacy.vue' /* webpackChunkName: "pages/users/_id/oasis/pharmacy" */))
const _4ce6aba0 = () => interopDefault(import('../pages/users/_id/oasis/providers.vue' /* webpackChunkName: "pages/users/_id/oasis/providers" */))
const _01aec550 = () => interopDefault(import('../pages/users/_id/oasis/records.vue' /* webpackChunkName: "pages/users/_id/oasis/records" */))
const _9ee4d6a8 = () => interopDefault(import('../pages/users/_id/oasis/rx-claims.vue' /* webpackChunkName: "pages/users/_id/oasis/rx-claims" */))
const _6b0c68d3 = () => interopDefault(import('../pages/users/_id/oasis/vaccination.vue' /* webpackChunkName: "pages/users/_id/oasis/vaccination" */))
const _4f829f9e = () => interopDefault(import('../pages/users/_id/playground/demo-data.vue' /* webpackChunkName: "pages/users/_id/playground/demo-data" */))
const _23078a40 = () => interopDefault(import('../pages/users/_id/playground/magic-link.vue' /* webpackChunkName: "pages/users/_id/playground/magic-link" */))
const _8f2ab0f8 = () => interopDefault(import('../pages/users/_id/syracuse/overview/index.vue' /* webpackChunkName: "pages/users/_id/syracuse/overview/index" */))
const _01e4c8f6 = () => interopDefault(import('../pages/users/_id/syracuse/PatientCardSyracuse.vue' /* webpackChunkName: "pages/users/_id/syracuse/PatientCardSyracuse" */))
const _2814acf7 = () => interopDefault(import('../pages/users/_id/oasis/assessment/create.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/create" */))
const _29ceb39c = () => interopDefault(import('../pages/users/_id/oasis/assessment/oasisAssessmentSvg.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/oasisAssessmentSvg" */))
const _bc10b58a = () => interopDefault(import('../pages/users/_id/oasis/assessment/_aid/index.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/_aid/index" */))
const _30270bf2 = () => interopDefault(import('../pages/users/_id/oasis/assessment/_aid/allergies.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/_aid/allergies" */))
const _3c097423 = () => interopDefault(import('../pages/users/_id/oasis/assessment/_aid/care_plans.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/_aid/care_plans" */))
const _d20e9664 = () => interopDefault(import('../pages/users/_id/oasis/assessment/_aid/finalize_cmr.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/_aid/finalize_cmr" */))
const _7746422d = () => interopDefault(import('../pages/users/_id/oasis/assessment/_aid/medications.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/_aid/medications" */))
const _1ef7cc4f = () => interopDefault(import('../pages/users/_id/oasis/assessment/_aid/patient_info.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/_aid/patient_info" */))
const _3b4c1e75 = () => interopDefault(import('../pages/users/_id/oasis/assessment/_aid/vaccinations.vue' /* webpackChunkName: "pages/users/_id/oasis/assessment/_aid/vaccinations" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _16d66644 = () => interopDefault(import('../pages/index/_name/index.vue' /* webpackChunkName: "pages/index/_name/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _9b9f232e,
    name: "404"
  }, {
    path: "/admin",
    component: _00588a40,
    name: "admin",
    children: [{
      path: "admin_users",
      component: _004031e8,
      name: "admin-index-admin_users"
    }, {
      path: "apps",
      component: _da0ec758,
      name: "admin-index-apps"
    }, {
      path: "dispensaries",
      component: _4e82b1e0,
      name: "admin-index-dispensaries"
    }, {
      path: "groups",
      component: _1e9f2376,
      name: "admin-index-groups"
    }, {
      path: "import_patients",
      component: _4f221f19,
      name: "admin-index-import_patients"
    }, {
      path: "measurement_durations",
      component: _2e659ce6,
      name: "admin-index-measurement_durations"
    }, {
      path: "onboard_medications",
      component: _e0462a46,
      name: "admin-index-onboard_medications"
    }, {
      path: "organizations",
      component: _734e7158,
      name: "admin-index-organizations"
    }, {
      path: "payment_coupons",
      component: _0d683a48,
      name: "admin-index-payment_coupons"
    }, {
      path: "provider_compensation",
      component: _e3073ac8,
      name: "admin-index-provider_compensation"
    }, {
      path: "referal_coupons",
      component: _79a73ca6,
      name: "admin-index-referal_coupons"
    }, {
      path: "rewards",
      component: _577fb698,
      name: "admin-index-rewards"
    }, {
      path: "roles",
      component: _0cd8d92e,
      name: "admin-index-roles"
    }, {
      path: "services",
      component: _155a6fc0,
      name: "admin-index-services"
    }, {
      path: "specialists",
      component: _3c9e3e93,
      name: "admin-index-specialists"
    }, {
      path: "states",
      component: _703393b8,
      name: "admin-index-states"
    }, {
      path: "tracks",
      component: _4d37b12a,
      name: "admin-index-tracks"
    }, {
      path: "users",
      component: _3bb6e2bc,
      name: "admin-index-users"
    }, {
      path: "website_hours",
      component: _c8097648,
      name: "admin-index-website_hours"
    }, {
      path: "documents/shared",
      component: _6f177f8a,
      name: "admin-index-documents-shared"
    }, {
      path: "groups/create",
      component: _460cd3f0,
      name: "admin-index-groups-create"
    }, {
      path: "library/topics",
      component: _fbcddacc,
      name: "admin-index-library-topics"
    }, {
      path: "tracks/alert/:alertId",
      component: _5859cf74,
      name: "admin-index-tracks-alert-alertId"
    }, {
      path: "library/topics/:id?/articles",
      component: _3f36515d,
      name: "admin-index-library-topics-id-articles"
    }, {
      path: "groups/:id",
      component: _21ad9361,
      name: "admin-index-groups-id"
    }, {
      path: "tracks/:id",
      component: _11202715,
      name: "admin-index-tracks-id"
    }, {
      path: "users/:userId",
      component: _010bb2dc,
      name: "admin-index-users-userId"
    }, {
      path: "apps/:id?/edit",
      component: _11c6922d,
      name: "admin-index-apps-id-edit"
    }, {
      path: "organizations/:id?/settings",
      component: _df7f3974,
      name: "admin-index-organizations-id-settings"
    }]
  }, {
    path: "/alerts",
    component: _679c151c,
    name: "alerts"
  }, {
    path: "/amdiabetes",
    component: _27cf61b4,
    name: "amdiabetes"
  }, {
    path: "/appointments",
    component: _4d8ff90e,
    name: "appointments"
  }, {
    path: "/cannahealrx",
    component: _30892d3c,
    name: "cannahealrx"
  }, {
    path: "/chats",
    component: _4a7bd00c,
    name: "chats"
  }, {
    path: "/civiclee",
    component: _6aae13b2,
    name: "civiclee"
  }, {
    path: "/dashboard",
    component: _aef8b776,
    name: "dashboard"
  }, {
    path: "/documents",
    component: _7d05a12e,
    name: "documents"
  }, {
    path: "/general_chat",
    component: _1fb5f954,
    name: "general_chat"
  }, {
    path: "/goodcannanow",
    component: _37d571f5,
    name: "goodcannanow"
  }, {
    path: "/inventory",
    component: _51da0de6,
    name: "inventory"
  }, {
    path: "/magic-link",
    component: _615db1df,
    name: "magic-link"
  }, {
    path: "/meeting",
    component: _d00a66a8,
    name: "meeting"
  }, {
    path: "/notifications",
    component: _3a19ba99,
    name: "notifications"
  }, {
    path: "/oasis",
    component: _776ebc88,
    name: "oasis"
  }, {
    path: "/organization_appointments",
    component: _b024f21e,
    name: "organization_appointments"
  }, {
    path: "/overview",
    component: _73bb7cbb,
    name: "overview"
  }, {
    path: "/patient_filters",
    component: _3b3e7db2,
    name: "patient_filters"
  }, {
    path: "/patients",
    component: _936a455a,
    name: "patients"
  }, {
    path: "/profile",
    component: _24b5cacc,
    name: "profile"
  }, {
    path: "/providers",
    component: _bd248e5a,
    name: "providers",
    children: [{
      path: ":id",
      component: _7b6095fe,
      name: "providers-index-id"
    }]
  }, {
    path: "/readings",
    component: _266b4f6c,
    name: "readings"
  }, {
    path: "/reminders",
    component: _2631d89c,
    name: "reminders"
  }, {
    path: "/reports",
    component: _29e23f30,
    name: "reports",
    children: [{
      path: "conversation_reports",
      component: _15dd80b2,
      name: "reports-index-conversation_reports"
    }, {
      path: "multiple_patients",
      component: _1bef8941,
      name: "reports-index-multiple_patients",
      children: [{
        path: "ccm",
        component: _7731fb3f,
        name: "reports-index-multiple_patients-index-ccm"
      }, {
        path: "general",
        component: _77be819a,
        name: "reports-index-multiple_patients-index-general"
      }]
    }, {
      path: "single_patients",
      component: _475e4446,
      name: "reports-index-single_patients"
    }]
  }, {
    path: "/team_monitoring",
    component: _a1d5adca,
    name: "team_monitoring"
  }, {
    path: "/testPage",
    component: _2b5e6d83,
    name: "testPage"
  }, {
    path: "/auth/forbidden",
    component: _279880d8,
    name: "auth-forbidden"
  }, {
    path: "/auth/forgot-password",
    component: _00db0160,
    name: "auth-forgot-password"
  }, {
    path: "/auth/login",
    component: _24cdd6f8,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _129a847f,
    name: "auth-logout"
  }, {
    path: "/auth/register",
    component: _476b85d8,
    name: "auth-register"
  }, {
    path: "/auth/success-fitbit",
    component: _55dc5f4a,
    name: "auth-success-fitbit"
  }, {
    path: "/auth/two_factor",
    component: _944de74c,
    name: "auth-two_factor"
  }, {
    path: "/cannahealrx/appointments",
    component: _7083c354,
    name: "cannahealrx-appointments"
  }, {
    path: "/cannahealrx/approved",
    component: _257ec852,
    name: "cannahealrx-approved"
  }, {
    path: "/cannahealrx/book",
    component: _259c4b09,
    name: "cannahealrx-book"
  }, {
    path: "/cannahealrx/booking",
    component: _2ce7612e,
    name: "cannahealrx-booking"
  }, {
    path: "/cannahealrx/no_show",
    component: _a691866a,
    name: "cannahealrx-no_show"
  }, {
    path: "/cannahealrx/patients",
    component: _b321b0a4,
    name: "cannahealrx-patients"
  }, {
    path: "/cannahealrx/rejected",
    component: _fea7e3c4,
    name: "cannahealrx-rejected"
  }, {
    path: "/civiclee/indexOld",
    component: _f85929a0,
    name: "civiclee-indexOld"
  }, {
    path: "/civiclee/landing",
    component: _5e703ca8,
    name: "civiclee-landing"
  }, {
    path: "/civiclee/landinggpt",
    component: _6d6103a2,
    name: "civiclee-landinggpt"
  }, {
    path: "/civiclee/landingOld",
    component: _67f5d4ea,
    name: "civiclee-landingOld"
  }, {
    path: "/civiclee/members",
    component: _1e36a89e,
    name: "civiclee-members"
  }, {
    path: "/civiclee/onboarding",
    component: _71de5d96,
    name: "civiclee-onboarding"
  }, {
    path: "/civiclee/success",
    component: _678c1198,
    name: "civiclee-success"
  }, {
    path: "/dashboard/all_patients",
    component: _5da89149,
    name: "dashboard-all_patients"
  }, {
    path: "/dashboard/call_list",
    component: _0f978152,
    name: "dashboard-call_list"
  }, {
    path: "/dashboard/my_patients",
    component: _de7e5218,
    name: "dashboard-my_patients"
  }, {
    path: "/dashboard/onboarding",
    component: _4ab1d238,
    name: "dashboard-onboarding"
  }, {
    path: "/dental/appointments",
    component: _0525e1df,
    name: "dental-appointments"
  }, {
    path: "/dental/library_de",
    component: _08fb4ff0,
    name: "dental-library_de"
  }, {
    path: "/dental/library_en",
    component: _0b2efc18,
    name: "dental-library_en"
  }, {
    path: "/dental/onboarding",
    component: _3527fc86,
    name: "dental-onboarding"
  }, {
    path: "/halee/landing",
    component: _b636feae,
    name: "halee-landing"
  }, {
    path: "/inventory/upload",
    component: _5a325454,
    name: "inventory-upload"
  }, {
    path: "/magic-link/expired",
    component: _6a15c572,
    name: "magic-link-expired"
  }, {
    path: "/monitoring/rpm",
    component: _2e852fea,
    name: "monitoring-rpm"
  }, {
    path: "/oasis/all_patients",
    component: _1068cff2,
    name: "oasis-all_patients"
  }, {
    path: "/oasis/my_patients",
    component: _2dccc1aa,
    name: "oasis-my_patients"
  }, {
    path: "/oasis/onboarding",
    component: _6e93a721,
    name: "oasis-onboarding"
  }, {
    path: "/oasis/review",
    component: _29dea8de,
    name: "oasis-review"
  }, {
    path: "/reports/loading",
    component: _1b5d991a,
    name: "reports-loading"
  }, {
    path: "/auth/patient/delete",
    component: _00fac126,
    name: "auth-patient-delete"
  }, {
    path: "/charts/apex/data",
    component: _8c1c1da0,
    name: "charts-apex-data"
  }, {
    path: "/charts/chartjs/DonutChart",
    component: _7ba10f0c,
    name: "charts-chartjs-DonutChart"
  }, {
    path: "/auth/patient/delete/success",
    component: _66befd44,
    name: "auth-patient-delete-success"
  }, {
    path: "/auth/patient/delete/:identifier/verify",
    component: _036b2ed6,
    name: "auth-patient-delete-identifier-verify"
  }, {
    path: "/auth/reset/:id?",
    component: _1a426815,
    name: "auth-reset-id"
  }, {
    path: "/auth/two_factor/:id",
    component: _fddb457c,
    name: "auth-two_factor-id"
  }, {
    path: "/civiclee/users/:id",
    component: _93e72fea,
    name: "civiclee-users-id"
  }, {
    path: "/dashboard/merge_patient/:id",
    component: _3d09071f,
    name: "dashboard-merge_patient-id"
  }, {
    path: "/civiclee/users/:id?/overview",
    component: _62c490d3,
    name: "civiclee-users-id-overview"
  }, {
    path: "/dashboard/merge_patient/:id?/:cid",
    component: _2436ae76,
    name: "dashboard-merge_patient-id-cid"
  }, {
    path: "/amdiabetes/:slug",
    component: _2618c06c,
    name: "amdiabetes-slug"
  }, {
    path: "/cannahealrx/:status",
    component: _250df821,
    name: "cannahealrx-status"
  }, {
    path: "/magic-link/:code?",
    component: _632862d9,
    name: "magic-link-code"
  }, {
    path: "/meeting/:id",
    component: _73f49cd2,
    name: "meeting-id"
  }, {
    path: "/monitoring/:id",
    component: _044554f8,
    name: "monitoring-id"
  }, {
    path: "/onboarding/:id",
    component: _2769b98b,
    name: "onboarding-id",
    children: [{
      path: "address",
      component: _d9521620,
      name: "onboarding-id-index-address"
    }, {
      path: "care_plan",
      component: _61db5bda,
      name: "onboarding-id-index-care_plan"
    }, {
      path: "care_provider",
      component: _145e6fdb,
      name: "onboarding-id-index-care_provider"
    }, {
      path: "contact",
      component: _3042f1c8,
      name: "onboarding-id-index-contact"
    }, {
      path: "insurance",
      component: _0980d854,
      name: "onboarding-id-index-insurance"
    }, {
      path: "patient_information",
      component: _5eb592ee,
      name: "onboarding-id-index-patient_information"
    }, {
      path: "specialists",
      component: _40c3d55e,
      name: "onboarding-id-index-specialists"
    }, {
      path: "tasks",
      component: _c3817eec,
      name: "onboarding-id-index-tasks"
    }]
  }, {
    path: "/patient_filters/:id",
    component: _634520c6,
    name: "patient_filters-id"
  }, {
    path: "/pg/:token",
    component: _c7bddf7a,
    name: "pg-token"
  }, {
    path: "/readings/:rid",
    component: _73bc22b8,
    name: "readings-rid"
  }, {
    path: "/team_monitoring/:id",
    component: _c6fe937a,
    name: "team_monitoring-id"
  }, {
    path: "/users/:id",
    component: _314d4fb8,
    name: "users-id"
  }, {
    path: "/meeting/:id/ended",
    component: _73da1f7f,
    name: "meeting-id-ended"
  }, {
    path: "/profile/:id/schedule",
    component: _69fe71b4,
    name: "profile-id-schedule"
  }, {
    path: "/users/:id?/achievements",
    component: _7c6f09c5,
    name: "users-id-achievements"
  }, {
    path: "/users/:id?/alerts",
    component: _670c3df8,
    name: "users-id-alerts"
  }, {
    path: "/users/:id?/appointments",
    component: _1875d156,
    name: "users-id-appointments"
  }, {
    path: "/users/:id?/attachments",
    component: _9ce574f6,
    name: "users-id-attachments",
    children: [{
      path: "documents",
      component: _1a5507a4,
      name: "users-id-attachments-index-documents"
    }, {
      path: "images",
      component: _75cfc2f2,
      name: "users-id-attachments-index-images"
    }, {
      path: "videos",
      component: _6b604bd2,
      name: "users-id-attachments-index-videos"
    }]
  }, {
    path: "/users/:id?/badges",
    component: _516e6d5e,
    name: "users-id-badges"
  }, {
    path: "/users/:id?/cannahealrx",
    component: _70e32bf4,
    name: "users-id-cannahealrx"
  }, {
    path: "/users/:id?/communications",
    component: _3edcbd84,
    name: "users-id-communications",
    children: [{
      path: "phone-call",
      component: _00b25efe,
      name: "users-id-communications-index-phone-call"
    }, {
      path: "text",
      component: _d5c978e4,
      name: "users-id-communications-index-text"
    }, {
      path: "video_call",
      component: _29f7d4c3,
      name: "users-id-communications-index-video_call"
    }]
  }, {
    path: "/users/:id?/dashboard",
    component: _45a67de9,
    name: "users-id-dashboard"
  }, {
    path: "/users/:id?/devices",
    component: _1208bf72,
    name: "users-id-devices"
  }, {
    path: "/users/:id?/documents",
    component: _5ea0090d,
    name: "users-id-documents"
  }, {
    path: "/users/:id?/insights",
    component: _c3e23688,
    name: "users-id-insights"
  }, {
    path: "/users/:id?/more",
    component: _4ddfea94,
    name: "users-id-more"
  }, {
    path: "/users/:id?/notes",
    component: _e7f80a54,
    name: "users-id-notes"
  }, {
    path: "/users/:id?/oasis",
    component: _87fc2540,
    name: "users-id-oasis"
  }, {
    path: "/users/:id?/overview",
    component: _c79ca64c,
    name: "users-id-overview",
    children: [{
      path: "fitbit",
      component: _b0b00e3e,
      name: "users-id-overview-index-fitbit"
    }, {
      path: "lab-vitals",
      component: _98e778a8,
      name: "users-id-overview-index-lab-vitals"
    }, {
      path: "medications",
      component: _7392434f,
      name: "users-id-overview-index-medications"
    }, {
      path: "vitals",
      component: _872d7c28,
      name: "users-id-overview-index-vitals"
    }]
  }, {
    path: "/users/:id?/playground",
    component: _362f8dc8,
    name: "users-id-playground"
  }, {
    path: "/users/:id?/reports",
    component: _187731d4,
    name: "users-id-reports"
  }, {
    path: "/users/:id?/rewards",
    component: _8f88860e,
    name: "users-id-rewards"
  }, {
    path: "/users/:id?/timers",
    component: _0b9db722,
    name: "users-id-timers"
  }, {
    path: "/users/:id?/tracks",
    component: _79a0daa9,
    name: "users-id-tracks"
  }, {
    path: "/users/:id?/cannahealrx/notes",
    component: _62e30075,
    name: "users-id-cannahealrx-notes"
  }, {
    path: "/users/:id?/dental/appointments",
    component: _9f053c74,
    name: "users-id-dental-appointments"
  }, {
    path: "/users/:id?/dental/notes",
    component: _5b788082,
    name: "users-id-dental-notes"
  }, {
    path: "/users/:id?/dental/overview",
    component: _9f6a506a,
    name: "users-id-dental-overview",
    children: [{
      path: "achievements",
      component: _c2f680d0,
      name: "users-id-dental-overview-index-achievements"
    }, {
      path: "tasks",
      component: _6ee0aeca,
      name: "users-id-dental-overview-index-tasks"
    }]
  }, {
    path: "/users/:id?/oasis/allergies",
    component: _34c7b0a8,
    name: "users-id-oasis-allergies"
  }, {
    path: "/users/:id?/oasis/assessment",
    component: _6b5df2a7,
    name: "users-id-oasis-assessment"
  }, {
    path: "/users/:id?/oasis/edit",
    component: _ced1ec28,
    name: "users-id-oasis-edit"
  }, {
    path: "/users/:id?/oasis/lab-data",
    component: _3bded6cc,
    name: "users-id-oasis-lab-data"
  }, {
    path: "/users/:id?/oasis/medical-claims",
    component: _95857556,
    name: "users-id-oasis-medical-claims"
  }, {
    path: "/users/:id?/oasis/medications",
    component: _707dcadc,
    name: "users-id-oasis-medications"
  }, {
    path: "/users/:id?/oasis/notes",
    component: _575683cf,
    name: "users-id-oasis-notes"
  }, {
    path: "/users/:id?/oasis/pharmacy",
    component: _0b6da1d6,
    name: "users-id-oasis-pharmacy"
  }, {
    path: "/users/:id?/oasis/providers",
    component: _4ce6aba0,
    name: "users-id-oasis-providers"
  }, {
    path: "/users/:id?/oasis/records",
    component: _01aec550,
    name: "users-id-oasis-records"
  }, {
    path: "/users/:id?/oasis/rx-claims",
    component: _9ee4d6a8,
    name: "users-id-oasis-rx-claims"
  }, {
    path: "/users/:id?/oasis/vaccination",
    component: _6b0c68d3,
    name: "users-id-oasis-vaccination"
  }, {
    path: "/users/:id?/playground/demo-data",
    component: _4f829f9e,
    name: "users-id-playground-demo-data"
  }, {
    path: "/users/:id?/playground/magic-link",
    component: _23078a40,
    name: "users-id-playground-magic-link"
  }, {
    path: "/users/:id?/syracuse/overview",
    component: _8f2ab0f8,
    name: "users-id-syracuse-overview"
  }, {
    path: "/users/:id?/syracuse/PatientCardSyracuse",
    component: _01e4c8f6,
    name: "users-id-syracuse-PatientCardSyracuse"
  }, {
    path: "/users/:id?/oasis/assessment/create",
    component: _2814acf7,
    name: "users-id-oasis-assessment-create"
  }, {
    path: "/users/:id?/oasis/assessment/oasisAssessmentSvg",
    component: _29ceb39c,
    name: "users-id-oasis-assessment-oasisAssessmentSvg"
  }, {
    path: "/users/:id?/oasis/assessment/:aid",
    component: _bc10b58a,
    name: "users-id-oasis-assessment-aid"
  }, {
    path: "/users/:id?/oasis/assessment/:aid/allergies",
    component: _30270bf2,
    name: "users-id-oasis-assessment-aid-allergies"
  }, {
    path: "/users/:id?/oasis/assessment/:aid/care_plans",
    component: _3c097423,
    name: "users-id-oasis-assessment-aid-care_plans"
  }, {
    path: "/users/:id?/oasis/assessment/:aid/finalize_cmr",
    component: _d20e9664,
    name: "users-id-oasis-assessment-aid-finalize_cmr"
  }, {
    path: "/users/:id?/oasis/assessment/:aid/medications",
    component: _7746422d,
    name: "users-id-oasis-assessment-aid-medications"
  }, {
    path: "/users/:id?/oasis/assessment/:aid/patient_info",
    component: _1ef7cc4f,
    name: "users-id-oasis-assessment-aid-patient_info"
  }, {
    path: "/users/:id?/oasis/assessment/:aid/vaccinations",
    component: _3b4c1e75,
    name: "users-id-oasis-assessment-aid-vaccinations"
  }, {
    path: "/",
    component: _f334fd00,
    name: "index",
    children: [{
      path: ":name",
      component: _16d66644,
      name: "index-name"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
