//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";
import debounce from "lodash/debounce";
export default {
  props: {
    chatWindowsCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters("chat", {
      conversations: "getInbox",
      nameForConversation: "nameForConversation",
      lastMessage: "lastMessage",
      initialsForConversation: "initialsForConversation",
      lastMessageTime: "lastMessageTime",
    }),
    ...mapState("chat", {
      unreadCount: (state) => state.unreadCount,
      loading: (state) => state.loading,
    }),

    groupChats() {
      let chats = this.conversations(this).filter((e) => {
        return !e.direct_message;
      });
      if (["All", "Groups"].includes(this.selectedFilter)) return chats;
      if (this.selectedFilter === "Direct") return [];
      if (this.selectedFilter === "Unread") {
        return chats.filter((e) => {
          return e.unread_count > 0;
        });
      }

      return chats;
    },
    directChats() {
      let chats = this.conversations(this).filter((e) => {
        return e.direct_message;
      });
      if (!this.$vianovaChat.direct_message_enabled) return [];
      if (this.selectedFilter === "All") return chats;
      if (this.selectedFilter === "Groups") return [];
      if (this.selectedFilter === "Unread") {
        return chats.filter((e) => {
          return e.unread_count > 0;
        });
      }
      return chats;
    },

    subtitle() {
      if (this.search && this.search.trim().length) {
        return `Sorry, we couldn’t find the name “${this.search}”. Please try again`;
      } else {
        return `Sorry, we couldn’t find any results. Please try again`;
      }
    },
  },
  data() {
    return {
      isHovered: false,
      search: "",
      selectedFilter: "All",
      checkForUnreadMessagesTimer: null,
      cancelToken: null,
      isOpen: false,
      currentPage: 1,
      perPage: 10,
      organizationId: localStorage.getItem("organizationId"),
    };
  },
  mounted() {
    this.$store.dispatch("chat/getInbox", {
      organizationId: this.organizationId,
      currentPage: this.currentPage,
    });
  },
  beforeDestroy() {
    // clearInterval(this.checkForUnreadMessagesTimer);
  },
  methods: {
    clearChat() {
      this.search = "";
    },
debounceSearch: debounce(function () {
      this.searchChats();
    }, 300),
    async searchChats() {
      this.cancelPreviousRequest();
      this.cancelToken = this.$axios.CancelToken.source();
      if (this.search.length) {
        await this.$axios
          .get(
            `${localStorage.getItem(
              "organizationId"
            )}/chat/search?page=1&per_page=20&search=${this.search}`,
            {
              cancelToken: this.cancelToken.token,
            }
          )
          .then((res) => {
            this.$store.commit("chat/SET_PATIENTS", res.data.data);
          })
          .catch((e) => {});
      } else {
        this.$store.dispatch("chat/getInbox", {
          organizationId: this.organizationId,
          currentPage: this.currentPage,
        });
      }
    },

    cancelPreviousRequest() {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
    },
    toggleInbox() {
      this.isOpen = !this.isOpen;
      this.$emit("inbox-toggle", this.isOpen);
      this.$store.dispatch("chat/getUnreadMessageCount");
      this.$store.dispatch("chat/getInbox", {
        organizationId: this.organizationId,
        currentPage: this.currentPage,
      });
    },

    selectChat(chat) {
      this.$store.dispatch("chat/selectChat", { chatId: chat.id });
      this.toggleInbox();
    },
  },
};
