export const state = () => ({
  patient: null,
  dndActionFrom: null,
  bookingData: null,
  patientPointsLogs: {
    data: [],
    pagination: null,
  },
});

export const mutations = {
  setPatient(state, data) {
    state.patient = data;
  },
  setDndActionFrom(state, data) {
    state.dndActionFrom = data;
  },
  updatePatientPoints(state, points) {
    if (state.patient) {
      state.patient.points = points;
    }
  },
  setBookingData(state, data) {
    state.bookingData = data;
  },
  setPatientPointsLogs(state, { data, page, pagination }) {
    if (page === 1) {
      state.patientPointsLogs.data = data;
    } else {
      state.patientPointsLogs.data = [...state.patientPointsLogs.data, ...data];
    }
    state.patientPointsLogs.pagination = pagination;
  },
};

export const actions = {
  async getBookingData({ commit, state }, { organization_id, id }) {
    commit("setBookingData", null);
    const data = await this.$axios.$get(
      `${organization_id}/booking/${id}/get-booking`
    );
    commit("setBookingData", data.data);
  },
  async getPatientDetails({ commit, state }, { organization_id, id }) {
    if (state.patient && state.patient.id !== id) {
      commit("setPatient", null);
    }
    const data = await this.$axios.$get(`${organization_id}/patients/${id}`);
    commit("setPatient", data.data);
  },
  async getPatientPointsLogs(
    { commit, state },
    { organization_id, patient_id, page = 1, per_page = 15 }
  ) {
    const response = await this.$axios.$get(
      `${organization_id}/patients/${patient_id}/points/logs`,
      {
        params: {
          page,
          per_page,
        },
      }
    );
    commit("setPatientPointsLogs", {
      data: response.data,
      page,
      pagination: response.pagination,
    });
  },
  async addPoints(
    { commit, state, dispatch },
    { organization_id, patient_id, points, reason }
  ) {
    await this.$axios.$post(
      `${organization_id}/patients/${patient_id}/points/add`,
      { points: +points, reason }
    );
    commit("updatePatientPoints", +state.patient.points + +points);
    dispatch("getPatientPointsLogs", {
      organization_id,
      patient_id,
      page: 1,
      per_page: 15,
    });
  },
  async removePoints(
    { commit, state, dispatch },
    { organization_id, patient_id, points, reason }
  ) {
    await this.$axios.$post(
      `${organization_id}/patients/${patient_id}/points/remove`,
      { points: +points, reason }
    );
    commit("updatePatientPoints", +state.patient.points - +points);
    dispatch("getPatientPointsLogs", {
      organization_id,
      patient_id,
      page: 1,
      per_page: 15,
    });
  },
  async updatePatientPoints({ commit, state }, { points }) {
    commit("updatePatientPoints", +state.patient.points + points);
  },
};

export const getters = {
  patient(state) {
    return state.patient;
  },
  dndAction(state) {
    return state.dndActionFrom;
  },
};
