export const state = () => ({
  currentUser: {},
  users: [],
  search: {},
  copyCarePlan: {},
  library: [],
  diseases: [],
  orgDiseases: [],
});

export const mutations = {
  SET_USER(state, data) {
    state.currentUser = data;
  },
  SET_USERS(state, { action, payload }) {
    action == "update"
      ? (state.users = state.users.map((e) =>
          e.id == payload.id ? payload : e
        ))
      : state.users.push(payload);
  },
  SET_SEARCH(state, data) {
    state.search = data;
  },
  SET_NEW_CARE_PLAN(state, data) {
    state.copyCarePlan = data;
  },
  SET_BOOKS(state, data) {
    state.library = data;
  },
  SET_ORG_DISEASES(state, data) {
    state.orgDiseases = data;
  },
  SET_DISEASES(state, data) {
    state.diseases = data;
  },
};

export const actions = {
  async fetchOrganizations({ state, commit }, { perPage, currentPage }) {
    const result = await this.$backend.getAdminOrganizations(
      perPage,
      currentPage
    );
    return result;
  },
  async fetchOrganizationsList({ state, commit }, { perPage, currentPage }) {
    const result = await this.$backend.getAdminOrganizationsList(
      perPage,
      currentPage
    );
    return result;
  },
  async fetchRoles({ state, commit }, { organizationId }) {
    let result = await this.$backend.getRoles(organizationId);
    return result;
  },
  async getOrganizationDetails({ state, commit }, { organizationId }) {
    let result = await this.$backend.getOrganizationDetails(organizationId);
    return result.data;
  },
  async createOrganization({ state, commit }, { payload }) {
    let result = await this.$backend.createOrganization(payload);
    return result.data;
  },
  async updateOrganization({ state, commit }, { organizationId, payload }) {
    let result = await this.$backend.updateOrganization(
      organizationId,
      payload
    );
    return result.data;
  },
  async deleteOrganization({ state, commit }, { organizationId }) {
    let result = await this.$backend.deleteOrganization(organizationId);
    return result.data;
  },
  async getOrganizationSettings({ state, commit }, { organizationId }) {
    let result = await this.$backend.getOrganizationSettings(organizationId);
    return result.data;
  },
  async updateOrganizationSettings(
    { state, commit },
    { organizationId, payload }
  ) {
    let result = await this.$backend.updateOrganizationSettings(
      organizationId,
      payload
    );
    return result.data;
  },
  async fetchUsers(
    { state, commit },
    { organizationId, role, search, currentPage = 1 }
  ) {
    let result = await this.$backend.getOrganizationUsers(
      organizationId,
      role,
      search,
      currentPage
    );
    return result;
  },
  async getUser({ state, commit }, { organizationId, userId }) {
    let result = await this.$backend.getUser(organizationId, userId);
    return result.data;
  },
  async getUserApplicableOrganizations(
    { state, commit },
    { organizationId, userId }
  ) {
    let result = await this.$backend.getUserApplicableOrganizations(
      organizationId,
      userId
    );
    return result.data;
  },
  async createUser({ state, commit }, { organizationId, payload }) {
    let result = await this.$backend.createUser(organizationId, payload);
    return result.data;
  },
  async updateUser({ state, commit }, { organizationId, userId, payload }) {
    let result = await this.$backend.updateUser(
      organizationId,
      userId,
      payload
    );
    return result.data;
  },
  async deleteUser({ state, commit }, { organizationId, userId }) {
    let result = await this.$backend.deleteUser(organizationId, userId);
    return result.data;
  },
  //
  async getSpecialities({ state, commit }, { organizationId }) {
    let result = await this.$backend.getSpecialities(organizationId);
    return result;
  },
  async getSpecialists(
    { state, commit },
    { organizationId, perPage, currentPage }
  ) {
    let result = await this.$backend.getAdminSpecialists(
      organizationId,
      perPage,
      currentPage
    );
    return result;
  },
  async getSpecialist({ state, commit }, { organizationId, specialistId }) {
    let result = await this.$backend.getSpecialist(
      organizationId,
      specialistId
    );
    return result.data;
  },
  async createSpecialist({ state, commit }, { organizationId, payload }) {
    let result = await this.$backend.createSpecialist(organizationId, payload);
    return result;
  },
  async updateSpecialist(
    { state, commit },
    { organizationId, specialistId, payload }
  ) {
    let result = await this.$backend.updateSpecialist(
      organizationId,
      specialistId,
      payload
    );
    return result;
  },
  async deleteAdminSpecialist(
    { state, commit },
    { organizationId, specialistId }
  ) {
    let result = await this.$backend.deleteSpecialist(
      organizationId,
      specialistId
    );
    return result;
  },
  //
  async fetchProviders({ state, commit }, { organizationId }) {
    let result = await this.$backend.getProviders(organizationId);
    return result.data;
  },
  async getProvider({ state, commit }, { organizationId, providerId }) {
    let result = await this.$backend.getProvider(organizationId, providerId);
    return result.data;
  },
  async createProvider({ state, commit }, { organizationId, payload }) {
    let result = await this.$backend.createProvider(organizationId, payload);
    return result.data;
  },
  async updateProvider(
    { state, commit },
    { organizationId, providerId, payload }
  ) {
    let result = await this.$backend.updateProvider(
      organizationId,
      providerId,
      payload
    );
    return result.data;
  },
  async deleteProvider({ state, commit }, { organizationId, providerId }) {
    let result = await this.$backend.deleteProvider(organizationId, providerId);
    return result.data;
  },
  //
  async getAdminActivityTypes({ state, commit }, { organizationId }) {
    let result = await this.$backend.getAdminActivityTypes(organizationId);
    return result.length ? result : result.data;
  },
  async getAdminMeasurementsDurations(
    { state, commit },
    { organizationId, perPage, currentPage }
  ) {
    let result = await this.$backend.getAdminMeasurementsDurations(
      organizationId,
      perPage,
      currentPage
    );
    return result;
  },
  async getAdminMeasurementDuration(
    { state, commit },
    { organizationId, measurementDurationId }
  ) {
    let result = await this.$backend.getAdminMeasurementDuration(
      organizationId,
      measurementDurationId
    );
    return result.data;
  },
  async createAdminMeasurementDuration(
    { state, commit },
    { organizationId, payload }
  ) {
    let result = await this.$backend.createAdminMeasurementDuration(
      organizationId,
      payload
    );
    return result.data;
  },
  async updateAdminMeasurementDuration(
    { state, commit },
    { organizationId, measurementDurationId, payload }
  ) {
    let result = await this.$backend.updateAdminMeasurementDuration(
      organizationId,
      measurementDurationId,
      payload
    );
    return result.data;
  },
  async deleteAdminMeasurementDuration(
    { state, commit },
    { organizationId, measurementDurationId }
  ) {
    let result = await this.$backend.deleteAdminMeasurementDuration(
      organizationId,
      measurementDurationId
    );
    return result.data;
  },
  //
  async fetchDiseases({ state, commit }, { organizationId, base_params }) {
    let result = await this.$backend.getDiseases(organizationId, base_params);
    if(base_params.global){
    commit("SET_DISEASES", result.data);
  } else {
    commit("SET_ORG_DISEASES", result.data)
  }
    return result;
  },
  async getDisease({ state, commit }, { organizationId, diseaseId }) {
    let result = await this.$backend.getDisease(organizationId, diseaseId);
    return result;
  },
  async createDisease({ state, commit }, { organizationId, payload }) {
    let result = await this.$backend.createDisease(organizationId, payload);
    return result;
  },
  async updateDisease(
    { state, commit },
    { organizationId, diseaseId, payload }
  ) {
    let result = await this.$backend.updateDisease(
      organizationId,
      diseaseId,
      payload
    );
    return result;
  },
  async deleteDisease({ state, commit }, { organizationId, diseaseId }) {
    let result = await this.$backend.deleteDisease(organizationId, diseaseId);
    commit(
      "SET_DISEASES",
      state.diseases.filter((disease) => disease.id != result.id)
    );
    return result;
  },

  // Disease Rule
  async getDiseaseRule(
    { state, commit },
    { organizationId, diseaseId, baseParams }
  ) {
    return await this.$backend.getDiseaseRule(
      organizationId,
      diseaseId,
      baseParams
    );
  },

  async createDiseaseRule(
    { state, commit },
    { organizationId, diseaseId, payload }
  ) {
    let result = await this.$backend.createDiseaseRule(
      organizationId,
      diseaseId,
      payload
    );
    return result;
  },

  async updateDiseaseRule(
    { state, commit },
    { organizationId, diseaseId, planId, payload }
  ) {
    let result = await this.$backend.updateDiseaseRule(
      organizationId,
      diseaseId,
      planId,
      payload
    );
    return result.data;
  },

  async deleteDiseaseRule(
    { state, commit },
    { organizationId, diseaseId, planId }
  ) {
    let result = await this.$backend.deleteDiseaseRule(
      organizationId,
      diseaseId,
      planId
    );
    return result.data;
  },
  // End of Disease Rule

  // Disease Plan
  async getDiseasePlan({ state, commit }, { organizationId, diseaseId }) {
    let result = await this.$backend.getDiseasePlan(organizationId, diseaseId);
    return result;
  },
  async createDiseasePlan(
    { state, commit },
    { organizationId, diseaseId, payload }
  ) {
    let result = await this.$backend.createDiseasePlan(
      organizationId,
      diseaseId,
      payload
    );
    return result;
  },
  async updateDiseasePlan(
    { state, commit },
    { organizationId, diseaseId, planId, payload }
  ) {
    let result = await this.$backend.updateDiseasePlan(
      organizationId,
      diseaseId,
      planId,
      payload
    );
    return result.data;
  },
  async deleteDiseasePlan(
    { state, commit },
    { organizationId, diseaseId, planId }
  ) {
    let result = await this.$backend.deleteDiseasePlan(
      organizationId,
      diseaseId,
      planId
    );
    return result.data;
  },
  //
  async getAdminMedications(
    { state, commit },
    { organizationId, perPage, currentPage }
  ) {
    let response = this.$backend.getAdminMedications(
      organizationId,
      perPage,
      currentPage
    );
    return response;
  },
  async getAdminMedication(
    { state, commit },
    { organizationId, medicationId }
  ) {
    let response = this.$backend.getAdminMedications(
      organizationId,
      medicationId
    );
    return response;
  },
  async createAdminMedication({ state, commit }, { organizationId, payload }) {
    let response = this.$backend.createAdminMedication(organizationId, payload);
    return response;
  },
  async updateAdminMedication(
    { state, commit },
    { organizationId, medicationId, payload }
  ) {
    let response = this.$backend.updateAdminMedication(
      organizationId,
      medicationId,
      payload
    );
    return response;
  },
  async deleteAdminMedication(
    { state, commit },
    { organizationId, medicationId }
  ) {
    let response = this.$backend.deleteAdminMedication(
      organizationId,
      medicationId
    );
    return response;
  },
  //
  async createBook({ state, commit }, { organizationId, payload }) {
    return await this.$backend.createBook(organizationId, payload);
  },
  async updateBook({ state, commit }, { organizationId, bookId, payload }) {
    return await this.$backend.updateBook(organizationId, bookId, payload);
  },
  async getBooks({ commit }, { organizationId, perPage, currentPage }) {
    let result = await this.$backend.getBooks(
      organizationId,
      perPage,
      currentPage
    );
    commit("SET_BOOKS", result.data);
    return result;
  },
};

export const getters = {
  getUser: (state) => () => {
    return state.currentUser;
  },
  getSearch: (state) => () => {
    return state.search;
  },
  getBooks: (state) => () => {
    return state.library;
  },
    getAdminDiseases: (state) => () => {
      return state.diseases;
    },
    getAdminOrgDiseases: (state) => () => {
      return state.orgDiseases;
    },
};
