export default function ({ $axios, redirect, app }) {
  $axios.onRequest((config) => {
    let locale = "en";
    if (localStorage.getItem("locale")) {
      locale = localStorage.getItem("locale");
    } else {
      locale = app.i18n.locale;
    }
    config.headers.common["Accept-Language"] = locale;
    config.headers.common["Content-Type"] = "application/json";
    config.headers.common["Authorization"] =
      localStorage.getItem("auth._token.local");

    const routeName = app.router.history.current.name;
    if (
      [
        "auth-login",
        "goodcannanow",
        "cannahealrx-book",
        "auth-patient-delete",
        "auth-patient-delete-identifier-verify",
      ].includes(routeName)
    ) {
      return;
    }
    try {
      if (app.$auth.strategy.refreshToken.status().valid()) {
        const refreshTokenTimestamp = localStorage.getItem(
          "refreshTokenTimestamp"
        );
        const now = new Date().getTime();
        const diff = now - refreshTokenTimestamp;
        if (diff > 60000) {
          app.$auth.refreshTokens();
          localStorage.setItem("refreshTokenTimestamp", new Date().getTime());
        }
      }
    } catch (e) {
      //
    }
  });
  $axios.onError((error) => {
    if (!$axios.isCancel(error)) {
      const routeName = app.router.history.current.name;
      // console.error("[axios] error", error);
      // console.error("[axios] response", error?.response);
      if (error?.response?.status == 401) {
        if (
          [
            "auth-login",
            "admin-index-users-userId",
            "goodcannanow",
            "cannahealrx-book",
            "auth-patient-delete",
            "auth-patient-delete-identifier-verify",
          ].includes(routeName)
        ) {
          return;
        }
        redirect("/auth/login");
      }
      if (error?.response?.status == 404) {
        // redirect("/404");
      }
    }
  });
}
