import uniqueColors from "@/helpers/randomColors";

const getPercentage = (value, total) => {
  return Number(((value / total) * 100).toFixed(2));
};

export const state = () => ({
  engagement: {
    metrics: {
      all_patients: 0,
      data: [],
    },
    allPatients: {
      all_patients: 0,
      data: [],
    },
    distribution: {
      all_patients: 0,
      providers: [],
    },
  },
  rtm: {
    categories: [0, 0, 0],
    cpt_codes: {
      enrolled: {
        percentage: null,
        count: null,
      },
      rtm_20_minutes_count: {
        percentage: null,
        count: null,
      },
      rtm_40_minutes_count: {
        percentage: null,
        count: null,
      },
      rtm_16_days_count: {
        percentage: null,
        count: null,
      },
    },
  },
  ccm: {
    cpt_codes: {
      enrolled: {
        percentage: null,
        count: null,
      },
      ccm_20_minutes_count: {
        percentage: null,
        count: null,
      },
      ccm_40_minutes_count: {
        percentage: null,
        count: null,
      },
    },
  },
  rpm: {
    cgm: [0, 0, 0],
    activities: {
      blood_pressure: {
        high: {
          percentage: null,
          count: null,
        },
        in_range: {
          percentage: null,
          count: null,
        },
        low: {
          percentage: null,
          count: null,
        },
      },
      glucose: {
        high: {
          percentage: null,
          count: null,
        },
        in_range: {
          percentage: null,
          count: null,
        },
        low: {
          percentage: null,
          count: null,
        },
      },
      heart_rate: {
        high: {
          percentage: null,
          count: null,
        },
        in_range: {
          percentage: null,
          count: null,
        },
        low: {
          percentage: null,
          count: null,
        },
      },
    },
    alerts: {},
    cpt_codes: {
      enrolled: {
        percentage: null,
        count: null,
      },
      rpm_16_days_count: {
        percentage: null,
        count: null,
      },
      rpm_20_minutes_count: {
        percentage: null,
        count: null,
      },
      rpm_40_minutes_count: {
        percentage: null,
        count: null,
      },
    },
  },
});

export const mutations = {
  setMetrics(state, data) {
    state.engagement.metrics.all_patients = data.data.total_engaged_patients;

    state.engagement.metrics.data = [
      {
        color: "#136A61",
        label: "Outgoing Calls",
        tooltip: "The total number of calls made to patients.",
        count: data.data.outgoing_calls,
      },
      {
        color: "#2E867D",
        tooltip: "The total number of calls received from patients.",
        label: "Incoming Calls",
        count: data.data.incoming_calls,
      },
      {
        color: "#4AC1B5",
        tooltip:
          "The total duration (in minutes) of all calls between staff and patients.",
        label: "Talk Minutes",
        count: data.data.talk_minutes,
      },
      {
        color: "#49C99A",
        label: "Call Encounter",
        tooltip:
          "The total number of calls involving meaningful patient care interactions",
        count: data.data.call_encounters,
      },
      {
        color: "#98F3D2",
        label: "Total SMS In",
        tooltip: "The total number of SMS messages received from patients.",
        count: data.data.total_sms_in,
      },
      {
        color: "#66DFCD",
        tooltip: "The total number of SMS messages sent to patients.",
        label: "Total SMS Out",
        count: data.data.total_sms_out,
      },
    ];
  },
  setDistribution(state, data) {
    let providers = data.data.providers;
    let patientCounts = data.data.providers.map(
      (provider) => provider.patients_count
    );
    let total = patientCounts.reduce((a, b) => a + b, 0);
    state.engagement.distribution.data = providers.map((e, index) => {
      return {
        ...e,
        color: uniqueColors[index],
        count: e.patients_count,
        tooltip: `Patients under this provider, shown in count and %.`,
        label: e.name,
        percentage: getPercentage(e.patients_count, total),
      };
    });
    state.engagement.distribution.all_patients = data.data.total_patients;
  },
  setAllPatients(state, data) {
    let total =
      data.data.rpm_patients +
      data.data.ccm_patients +
      data.data.rtm_patients +
      data.data.ccm_rpm_patients +
      data.data.ccm_rtm_patients;
    state.engagement.allPatients.all_patients = data.data.all_patients;
    state.engagement.allPatients.data = [
      {
        color: "#2E867D",
        label: "RPM Patients",
        tooltip: "Patients in Remote Patient Monitoring (RPM).",
        count: data.data.rpm_patients,
        percentage: getPercentage(data.data.rpm_patients, total),
      },
      {
        color: "#245762",
        label: "CCM Patients",
        tooltip: "Patients in Chronic Care Management (CCM).",
        count: data.data.ccm_patients,
        percentage: getPercentage(data.data.ccm_patients, total),
      },
      {
        color: "#00798C",
        label: "RTM Patients",
        tooltip: "Patients in Remote Therapeutic Management (RTM).",
        count: data.data.rtm_patients,
        percentage: getPercentage(data.data.rtm_patients, total),
      },
      {
        color: "#60D186",
        label: "CCM + RPM Patients",
        tooltip: "Patients enrolled in both CCM and RPM programs.",
        count: data.data.ccm_rpm_patients,
        percentage: getPercentage(data.data.ccm_rpm_patients, total),
      },
      {
        color: "#66DFCD",
        label: "CCM + RTM Patients",
        tooltip: "Patients enrolled in both CCM and RTM programs.",
        count: data.data.ccm_rtm_patients,
        percentage: getPercentage(data.data.ccm_rtm_patients, total),
      },
    ];
  },
  setRTMCategorize(state, data) {
    state.rtm.categories = [
      data["data"].active_count,
      data["data"].inactive_count,
      data["data"].dormant_count,
    ];
  },
  setRTMData(state, data) {
    state.rtm.cpt_codes.enrolled.percentage =
      data.percentage_change.rtm_enrolled;
    state.rtm.cpt_codes.enrolled.count = data.selected_month.rtm_enrolled;

    state.rtm.cpt_codes.rtm_20_minutes_count.percentage =
      data.percentage_change.rtm_20_minutes_count;
    state.rtm.cpt_codes.rtm_20_minutes_count.count =
      data.selected_month.rtm_20_minutes_count;

    state.rtm.cpt_codes.rtm_40_minutes_count.percentage =
      data.percentage_change.rtm_40_minutes_count;
    state.rtm.cpt_codes.rtm_40_minutes_count.count =
      data.selected_month.rtm_40_minutes_count;

    state.rtm.cpt_codes.rtm_16_days_count.percentage =
      data.percentage_change.rtm_16_days_count;
    state.rtm.cpt_codes.rtm_16_days_count.count =
      data.selected_month.rtm_16_days_count;
  },
  setCGMData(state, data) {
    state.rpm.cgm = [
      data["data"]["active_count"],
      data["data"]["inactive_count"],
      data["data"]["dormant_count"],
    ];
  },

  setCCMData(state, data) {
    state.ccm.cpt_codes.enrolled.percentage =
      data.percentage_change.ccm_enrolled;
    state.ccm.cpt_codes.enrolled.count = data.selected_month.ccm_enrolled;
    state.ccm.cpt_codes.ccm_20_minutes_count.percentage =
      data.percentage_change["ccm_20_minutes_count"];
    state.ccm.cpt_codes.ccm_20_minutes_count.count =
      data.selected_month["ccm_20_minutes_count"];
    state.ccm.cpt_codes.ccm_40_minutes_count.percentage =
      data.percentage_change["ccm_40_minutes_count"];
  },
  setActivitiesData(state, data) {
    state.rpm.activities = data;
  },
  setAlertData(state, data) {
    state.rpm.alerts = data;
  },
  setRPMData(state, data) {
    state.rpm.cpt_codes.enrolled.percentage =
      data.percentage_change.rpm_enrolled;
    state.rpm.cpt_codes.enrolled.count = data.selected_month.rpm_enrolled;
    state.rpm.cpt_codes.rpm_16_days_count.percentage =
      data.percentage_change["rpm_16_days_count"];
    state.rpm.cpt_codes.rpm_16_days_count.count =
      data.selected_month["rpm_16_days_count"];
    state.rpm.cpt_codes["rpm_20_minutes_count"].percentage =
      data.percentage_change["rpm_20_minutes_count"];
    state.rpm.cpt_codes["rpm_20_minutes_count"].count =
      data.selected_month["rpm_20_minutes_count"];
    state.rpm.cpt_codes["rpm_40_minutes_count"].percentage =
      data.percentage_change["rpm_40_minutes_count"];
    state.rpm.cpt_codes["rpm_40_minutes_count"].count =
      data.selected_month["rpm_40_minutes_count"];
  },
};

export const actions = {
  async getMetrics({ commit }, { in_program_on, coordinator_id, provider_id }) {
    const result = await this.$axios.get(
      `${localStorage.getItem(
        "organizationId"
      )}/dashboard/patients/engagement-metrics`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setMetrics", result.data);
  },
  async getDistribution(
    { commit },
    { in_program_on, coordinator_id, provider_id }
  ) {
    const result = await this.$axios.get(
      `${localStorage.getItem(
        "organizationId"
      )}/dashboard/patients/provider-stats`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setDistribution", result.data);
  },
  async getAllPatients(
    { commit },
    { in_program_on, coordinator_id, provider_id }
  ) {
    const result = await this.$axios.get(
      `${localStorage.getItem("organizationId")}/dashboard/patients/counts`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setAllPatients", result.data);
  },
  async getRTMCategorize(
    { commit },
    { in_program_on, coordinator_id, provider_id }
  ) {
    const result = await this.$axios.get(
      `${localStorage.getItem(
        "organizationId"
      )}/dashboard/rtm/patients/categorize`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setRTMCategorize", result.data);
  },
  async getRtmData({ commit }, { in_program_on, coordinator_id, provider_id }) {
    const result = await this.$axios.$get(
      `${localStorage.getItem("organizationId")}/dashboard/rtm/stats`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setRTMData", result.data);
  },
  async getActivities(
    { commit },
    { in_program_on, coordinator_id, provider_id }
  ) {
    const result = await this.$axios.$get(
      `${localStorage.getItem(
        "organizationId"
      )}/dashboard/activity-responses/stats`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setActivitiesData", result.data);
  },
  async getRPMData({ commit }, { in_program_on, coordinator_id, provider_id }) {
    const result = await this.$axios.$get(
      `${localStorage.getItem("organizationId")}/dashboard/rpm/stats`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setRPMData", result.data);
  },
  async getAlertData(
    { commit },
    { in_program_on, coordinator_id, provider_id }
  ) {
    const result = await this.$axios.$get(
      `${localStorage.getItem("organizationId")}/dashboard/alerts/stats`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setAlertData", result.data);
  },
  async getCGM({ commit }, { in_program_on, coordinator_id, provider_id }) {
    const result = await this.$axios.get(
      `${localStorage.getItem(
        "organizationId"
      )}/dashboard/cgm/patients/categorize`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setCGMData", result.data);
  },
  async getCCM({ commit }, { in_program_on, coordinator_id, provider_id }) {
    const result = await this.$axios.$get(
      `${localStorage.getItem("organizationId")}/dashboard/ccm/stats`,
      {
        params: {
          in_program_on: in_program_on,
          coordinator_id: coordinator_id,
          provider_id: provider_id,
        },
      }
    );
    commit("setCCMData", result.data);
  },
};

export const getters = {
  getRpmData: (state) => (key) => {
    return state.rpm.cpt_codes;
  },
  getActivitiesData: (state) => () => {
    return state.rpm.activities;
  },
  getAlertsData: (state) => () => {
    return state.rpm.alerts;
  },
  getCGMData: (state) => () => {
    return state.rpm.cgm;
  },
  getCCMData: (state) => () => {
    return state.ccm.cpt_codes;
  },
  getRTMData: (state) => () => {
    return state.rtm.cpt_codes;
  },
  getRTMCategorize: (state) => () => {
    return state.rtm.categories;
  },
  getAllPatients: (state) => () => {
    return state.engagement.allPatients;
  },
  getDistribution: (state) => () => {
    return state.engagement.distribution;
  },
  getMetrics: (state) => () => {
    return state.engagement.metrics;
  },
};
